import { Typography, makeStyles } from "@material-ui/core";
import { FoodMapItem } from "../FoodMapItem/FoodMapItem";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

export const FoodMap: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <div>
        <Typography>ზოგადი წესები და ფილოსოფია</Typography>

        <Typography>
          შაქრის ცომის ამოღება არ ნიშნავს რომ დაბალანსებულს არ ვჭამ. glycemic
          index.
        </Typography>
      </div>

      <div className={classes.root}>
        <FoodMapItem imageName="pork.png" titleText="ღორის ხორცი - Pork" />

        <FoodMapItem imageName="beaf.png" titleText="ძროხის ხორცი - Beaf" />

        <FoodMapItem
          imageName="chicken.jpeg"
          titleText="ქათამი (სოფლის უმჯობესია, შავი ხორცი უმჯობესია) - Chicken"
        />

        <FoodMapItem imageName="cutlet-beaf.png" titleText="ფარში" />

        <FoodMapItem
          imageName="organ-meat.jpeg"
          titleText="გულღვიძლი - Organ meat"
        />

        <FoodMapItem imageName="salo.jpeg" titleText="სალა - Salo" />

        <FoodMapItem imageName="salmon.png" titleText="ორაგული - Salmon" />

        <FoodMapItem
          imageName="broth.jpeg"
          titleText="ბულიონი, ძვლის ნახარში - Broth, Bone broth"
        />

        <FoodMapItem imageName="tuna.jpeg" titleText="თინუსი - Tuna" />

        <FoodMapItem imageName="trout.jpeg" titleText="კალმახი - Trout" />

        <FoodMapItem
          imageName="crab-meat.jpeg"
          titleText="კრაბი (ალბათ ყალბი იყიდება ძირითადად) - Crab meat"
        />

        <FoodMapItem
          imageName="eggs.jpeg"
          titleText="კვერცხი (უმჯობესია სოფლის) - Eggs"
        />

        <FoodMapItem
          imageName="caviar.png"
          titleText="ხიზილალა, წითელი, შავი - Caviar"
        />

        <FoodMapItem imageName="acovado.png" titleText="ავოკადო - Avocado" />

        <FoodMapItem
          imageName="hummus.png"
          titleText="ჰუმუსი (ზომიერად) - Hummus"
        />

        <FoodMapItem
          imageName="low-carb-vegetables.jpeg"
          titleText="ბოსტნეული (ზოგადი წესია: მიწის ზემოთ რაც ხარობს ნაკლები სიტკბო აქ) - Vegetables"
        />

        <FoodMapItem
          imageName="asparagus.jpeg"
          titleText="სატაცური - Asparagus"
        />

        <FoodMapItem imageName="broccoli.jpeg" titleText="ბროკოლი - Broccoli" />

        <FoodMapItem
          imageName="dummy.png"
          titleText="ყვავილოვანი კომბოსტო - Cauliflower"
        />

        <FoodMapItem imageName="dummy.png" titleText="ფოთლოვანი ბოსტნეული" />

        <FoodMapItem
          imageName="dummy.png"
          titleText="ბრიუსელის ყლორტები - Brussel sprouts"
        />

        <FoodMapItem
          imageName="dummy.png"
          titleText="ბულგარული - Bell pepper"
        />

        <FoodMapItem imageName="dummy.png" titleText="ისპანახი - Spinach" />

        <FoodMapItem
          imageName="dummy.png"
          titleText="სალათის ფოთლები - Lettuce"
        />

        <FoodMapItem imageName="zucchini.png" titleText="ყაბაყი - Zucchini" />

        <FoodMapItem imageName="dummy.png" titleText="ხახვი - Onion" />

        <FoodMapItem imageName="dummy.png" titleText="ნიორი - Garlic" />

        <FoodMapItem imageName="dummy.png" titleText="გოგრა - Pumpkin" />

        <FoodMapItem
          imageName="lemon.png"
          titleText="ლიმონი, ლაიმი - Lemon, Lime"
        />

        <FoodMapItem
          imageName="sun-dried-tomatos.png"
          titleText="პომიდვრის ჩირი (ზომიერად) - Sun dried tomatoes"
        />

        <FoodMapItem imageName="dummy.png" titleText="სოკო" />

        <FoodMapItem imageName="dummy.png" titleText="ხის სოკო" />

        <FoodMapItem imageName="dummy.png" titleText="ქამა სოკო" />

        <FoodMapItem imageName="olives.png" titleText="ზეთის ხილი - Olives" />

        <FoodMapItem
          imageName="dummy.png"
          titleText="მწნილები - Pickled food"
        />

        <FoodMapItem
          imageName="butter.png"
          titleText="კარაქი (უმჯობესია სოფლის) - Butter"
        />

        <FoodMapItem
          imageName="olive-oil.png"
          titleText="ზეითუნის ზეთი (მუქ ბოთლში, რათა მზისგან მეტად დაცული იყოს - Extra Virgin Olive Oil"
        />

        <FoodMapItem
          imageName="mct-oil.png"
          titleText="MCT ზეთი (მედიცინური გამოყენების, ყავაში ერთი კოზი, სმუზიში, სალათაზე მოსასხმელად) - MCT Oil (Medium Chain Triglycerides)"
        />
        <FoodMapItem
          imageName="dummy.png"
          titleText="ქოქოსის ზეთი - Coconut oil"
        />
        <FoodMapItem imageName="dummy.png" titleText="aaaaa" />

        <FoodMapItem
          imageName="pistachio.png"
          titleText="ფისტაშკა - Pistachio"
        />

        <FoodMapItem imageName="dummy.png" titleText="კეშიუ - Cashew" />

        <FoodMapItem
          imageName="dummy.png"
          titleText="თხილი (გრძელი უფრო გემრიელია) - Hazelnuts"
        />

        <FoodMapItem imageName="dummy.png" titleText="ნიგოზი - Walnut" />

        <FoodMapItem imageName="dummy.png" titleText="ნუში - Almonds" />

        <FoodMapItem imageName="dummy.png" titleText="მაკადემია - Macadamia" />

        <FoodMapItem
          imageName="dummy.png"
          titleText="ბრაზილიური თხილი- Brazil nuts"
        />

        <FoodMapItem
          imageName="dummy.png"
          titleText="მზესუმზირა - Sunflower seeds"
        />

        <FoodMapItem
          imageName="dummy.png"
          titleText="გოგრის მარცვლები - Pumpkin seeds"
        />

        <FoodMapItem
          imageName="raspberry.png"
          titleText="ჟოლო (მალინა, ზომიერად) - Raspberry"
        />

        <FoodMapItem
          imageName="dummy.png"
          titleText="მოცვი (ზომიერად) - Blueberry"
        />

        <FoodMapItem
          imageName="dummy.png"
          titleText="მარწყვი (ზომიერად) - Strawberry"
        />

        <FoodMapItem
          imageName="dummy.png"
          titleText="მაყვალი (ზომიერად) - Blackberry"
        />

        <FoodMapItem imageName="dummy.png" titleText="ყავა - Coffee" />

        <FoodMapItem imageName="dummy.png" titleText="ჩაი - Tea" />

        <FoodMapItem imageName="dummy.png" titleText="მდოგვი - Mustard" />

        <FoodMapItem
          imageName="dummy.png"
          titleText={
            <a>
              ერითრიტოლი (ერთერთი რეალური შაქრის შემცვლელი,{" "}
              <a target="blank" href="https://goo.gl/maps/atwbCzk9kDYNAHfN9">
                Sada აბაშიძეზე
              </a>{" "}
              - აკეთებს რეალურ უშაქრო ნამცხრებს, Bolt Food-ზე აქვთ გამოძახება) -
              Erythritol
            </a>
          }
        />

        <FoodMapItem imageName="dummy.png" titleText="aaaaa" />
      </div>
    </>
  );
};
