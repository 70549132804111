import { Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 8px 5px;
  cursor: pointer;
  width: min-content;

  img {
    height: 120px;
    border-radius: 5px;
    display: flex;
  }
`;

const ImgWrapper = styled.div`
  padding: 6px;
  background: #9ee9d894;
  border-radius: 5px;
`;

const Title = styled(Typography)`
  padding: 2px;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
`;

type Props = {
  imageName: string;
  titleText: string | JSX.Element;
  tooltipText?: string;
};

export const FoodMapItem: React.FC<Props> = ({
  imageName,
  titleText,
  tooltipText,
}) => {
  return (
    <Wrapper>
      <Tooltip title={tooltipText || ""}>
        <div>
          <ImgWrapper>
            <img src={`/foodMap/${imageName}`} />
          </ImgWrapper>

          <Title>{titleText}</Title>
        </div>
      </Tooltip>
    </Wrapper>
  );
};
